import React, {useState, useRef} from 'react';
import axios from 'axios';
import {ClipLoader} from 'react-spinners';
import CreatableSelect from 'react-select/creatable';

function App() {
    const [input, setInput] = useState('');
    const [topic, setTopic] = useState(null);
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const responseRef = useRef(null);
    const [industry, setIndustry] = useState(null);

    const handleSelectChange = (value, actionMeta) => {
        if (actionMeta.action === "select-option" || actionMeta.action === "create-option") {
            if (actionMeta.name === "topic") {
                setTopic(value.value);
            } else if (actionMeta.name === "industry") {
                setIndustry(value.value);
            }
        }
    };

    const topicsOptions = [
        {value: 'Strategic Planning', label: 'Strategic Planning'},
        {value: 'Process Optimization', label: 'Process Optimization'},
        {value: 'Organizational Development', label: 'Organizational Development'},
        {value: 'Change Management', label: 'Change Management'},
        {value: 'Financial Management', label: 'Financial Management'},
        {value: 'Technology Implementation', label: 'Technology Implementation'},
        {value: 'Market Analysis and Business Development', label: 'Market Analysis and Business Development'}
    ];

    const industryOptions = [
        {value: 'Aerospace', label: 'Aerospace'},
        {value: 'Agriculture', label: 'Agriculture'},
        {value: 'Automotive', label: 'Automotive'},
        {value: 'Biotechnology', label: 'Biotechnology'},
        {value: 'Construction', label: 'Construction'},
        {value: 'Consumer Goods', label: 'Consumer Goods'},
        {value: 'Education', label: 'Education'},
        {value: 'Energy', label: 'Energy'},
        {value: 'Finance', label: 'Finance'},
        {value: 'Food & Beverage', label: 'Food & Beverage'},
        {value: 'Healthcare', label: 'Healthcare'},
        {value: 'Hospitality', label: 'Hospitality'},
        {value: 'IT', label: 'Information Technology'},
        {value: 'Manufacturing', label: 'Manufacturing'},
        {value: 'Media & Entertainment', label: 'Media & Entertainment'},
        {value: 'Pharmaceuticals', label: 'Pharmaceuticals'},
        {value: 'Real Estate', label: 'Real Estate'},
        {value: 'Retail', label: 'Retail'},
        {value: 'Telecommunications', label: 'Telecommunications'},
        {value: 'Transportation', label: 'Transportation'}
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const topicValue = topic ? topic.value : '';
            const industryValue = industry ? industry.value : '';
            const result = await axios.post('https://case-study-gen-api.hafen.io/generator'/*'http://10.1.0.6:3003/generator'*/, {
                input,
                topic: topicValue,
                industry: industryValue
            }, {
                headers: {
                    'Authorization': password,
                    'Content-Type': 'application/json',
                }
            });
            setResponse(result.data.response);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResponse("Error fetching data.");
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = () => {
        if (responseRef.current) {
            navigator.clipboard.writeText(responseRef.current.innerText);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-200">
            <div className="p-10 bg-white rounded shadow-xl w-2/3">
                <h1 className="text-2xl font-bold mb-6 text-center">Case Study Generator</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Topic</label>
                        <CreatableSelect
                            value={topic}
                            onChange={(selectedOption) => setTopic(selectedOption)}
                            options={topicsOptions}
                            isClearable
                            isSearchable
                            placeholder="Select or type a topic..."
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Industry</label>
                        <CreatableSelect
                            value={industry}
                            onChange={(selectedOption) => setIndustry(selectedOption)}
                            options={industryOptions}
                            isClearable
                            isSearchable
                            placeholder="Select or type an industry..."
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Input</label>
                        <textarea
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            rows="10"
                            className="mt-2 p-3 w-full h-48 border rounded-md resize-y"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-2 p-3 w-full h-14 border rounded-md"
                        />
                    </div>

                    {loading ? (
                        <div className="flex justify-center items-center p-3">
                            <ClipLoader color="#2563EB"/>
                        </div>
                    ) : (
                        <button type="submit" className="p-3 bg-blue-500 text-white rounded w-full">Submit</button>
                    )}
                </form>
                <pre ref={responseRef} className="mt-6 h-48 border rounded-md overflow-y-scroll p-3 bg-gray-100">
                    {response}
                </pre>
                <button onClick={handleCopy} className="mt-2 p-2 bg-green-500 text-white rounded w-full">Copy Text
                </button>
            </div>
        </div>
    );
}

export default App;
